export const userRoute = [
  //user navigations
  {
    name: "verify-account",
    path: "/verifyme",
    component: () =>
      import(
        /* webpackChunkName:"user-verify-account" */ "@/views/verify-account"
      ),
    meta: {
      title: "Verify Account",
      auth: "user",
    },
  },
  {
    name: "transactions",
    path: "/account/transactions/:tab?",
    component: () =>
      import(
        /* webpackChunkName:"user-transactions" */ "@/views/user/transactions"
      ),
    meta: {
      title: "Transactions",
      auth: "user",
      roles: ["admin", "operations", "accountant", "customer support"],
      breadcrumbs: [],
    },
  },
  {
    name: "ledgerDetails",
    path: "/account/transactions/ledger/:reference",
    component: () =>
      import(/* webpackChunkName:"user-ledger" */ "@/views/user/ledgerDetails"),
    meta: {
      title: "Ledger Details",
      auth: "user",
      roles: ["admin", "operations", "accountant", "customer support"],
      breadcrumbs: [{ title: "Transactions", route: "transactions" }],
    },
    params: true,
  },
  /*{
      name:'account-verification',
      path:'/account/account-verification',
      component: ()=>import(/!* webpackChunkName:"user-verification" *!/"@/views/user/settings"),
      meta:{
        title:'Account Verification',
        auth:'user'
      }

    },*/
  {
    name: "invoice",
    path: "/account/invoice",
    component: () =>
      import(/* webpackChunkName:"user-invoice" */ "@/views/user/invoice"),
    meta: {
      title: "Invoice",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "customers",
    path: "/account/customers",
    component: () =>
      import(/* webpackChunkName:"user-customer" */ "@/views/user/customers"),
    meta: {
      title: "Customer",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [],
    },
  },
  {
    name: "paybill",
    path: "/account/paybills",
    component: () =>
      import(/* webpackChunkName:"user-paybill" */ "@/views/user/paybill"),
    meta: {
      title: "Pay Bills",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [],
    },
    params: true,
  },
  {
    name: "transactionHistory",
    path: "/account/transaction-history",
    component: () =>
      import(
        /* webpackChunkName:"user-transactionHistory" */ "@/views/user/transactionHistory"
      ),
    meta: {
      title: "Transaction History",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [{ title: "Transactions", route: "transactions" }],
    },
  },
  {
    name: "auditLog",
    path: "/account/audit-logs",
    component: () =>
      import(/* webpackChunkName:"user-audit-logs" */ "@/views/user/auditLog"),
    meta: {
      title: "Audit Logs",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [],
    },
  },
  {
    name: "success",
    path: "/account/transaction/success",
    component: () =>
      import(
        /* webpackChunkName:"user-transaction-success" */ "@/views/user/success"
      ),
    meta: {
      title: "Success",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [],
    },
  },
  {
    name: "addon",
    path: "/account/addon",
    component: () =>
      import(/* webpackChunkName:"user-addon" */ "@/views/user/addon"),
    meta: {
      title: "Add-On Services",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
    params: true,
  },
  {
    name: "paymentLink",
    path: "/account/payment-link/:tab?",
    component: () =>
      import(/* webpackChunkName:"user-payment" */ "@/views/user/paymentLink"),
    meta: {
      title: "Payment Links",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "Receive Money", route: "receive-money" }],
    },
    params: true,
  },
  {
    name: "paymentLinkDetails",
    path: "/account/paymentlink-link/:pageid/:pagetype",
    component: () =>
      import(
        /* webpackChunkName:"user-payment-link-details" */ "@/views/user/paymentLinkDetails"
      ),
    meta: {
      title: "Payment Link Details",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "Receive Money", route: "receive-money" },
        { title: "Payment Links", route: "paymentLink" },
      ],
    },
    params: true,
  },
  {
    name: "two-factor",
    path: "/account/two-factor",
    component: () =>
      import(
        /* webpackChunkName:"user-two-factor" */ "@/views/user/two-factor"
      ),
    meta: {
      title: "Two Factor Authentication",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "Settings", route: "settings" }],
    },
    params: true,
  },

  {
    name: "payout",
    path: "/account/payout",
    component: () =>
      import(/* webpackChunkName:"user-payout" */ "@/views/user/payout"),
    meta: {
      title: "Payout Page",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "payoutDetails",
    path: "/account/payout/:reference",
    component: () =>
      import(
        /* webpackChunkName:"user-payout-details" */ "@/views/user/payoutDetails"
      ),
    meta: {
      title: "Payout Detail Page",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Payouts", route: "payout" },
      ],
    },
    params: true,
  },
  {
    name: "completeBusiness",
    path: "/account/complete-business",
    component: () =>
      import(
        /* webpackChunkName:"user-complete-business" */ "@/views/user/completeBusiness"
      ),
    meta: {
      title: "Complete Business Setup",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [],
    },
  },
  {
    name: "referrer",
    path: "/account/referrals",
    component: () =>
      import(/* webpackChunkName:"user-referrer" */ "@/views/user/referrer"),
    meta: {
      title: "Referrals",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [],
    },
  },
  {
    name: "beneficiary",
    path: "/account/beneficiary-list",
    component: () =>
      import(
        /* webpackChunkName:"user-beneficiary" */ "@/views/user/beneficiary.vue"
      ),
    meta: {
      title: "Beneficiary List",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "multipayment",
    path: "/account/bulk-payments/sendmoney",
    component: () =>
      import(
        /* webpackChunkName:"user-multipayment" */ "@/views/user/mulitipayment.vue"
      ),
    meta: {
      title: "Bulk Payment | Send Money",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "schedule",
    path: "/account/scheduled-payments",
    component: () =>
      import(
        /* webpackChunkName:"user-scheduled-payment" */ "@/views/user/schedule.vue"
      ),
    meta: {
      title: "Scheduled Payments",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "schedulerHistory",
    path: "/account/scheduler-history/:random/:transid/:txref",
    component: () =>
      import(
        /* webpackChunkName:"user-scheduler-history" */ "@/views/user/schedulerHistory.vue"
      ),
    meta: {
      title: "Scheduled Payment History",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Scheduled Payments", route: "schedule" },
      ],
    },
  },
  {
    name: "customerDetails",
    path: "/account/customers/details/:ref/:id",
    component: () =>
      import(
        /* webpackChunkName:"user-customer-details" */ "@/views/user/customerDetails.vue"
      ),
    meta: {
      title: "Customer Details",
      auth: "user",
      breadcrumbs: [{ title: "Customers", route: "customers" }],
    },
  },
  {
    name: "bulkpayment",
    path: "/account/bulk-payments",
    component: () =>
      import(
        /* webpackChunkName:"user-bulk-payments" */ "@/views/user/bulkpayment.vue"
      ),
    meta: {
      title: "Bulk Payment",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "bulkairtime",
    path: "/account/bulk-payments/airtime",
    component: () =>
      import(
        /* webpackChunkName:"user-bulk-airtime" */ "@/views/user/bulkairtime.vue"
      ),
    meta: {
      title: "Bulk Payment | Airtime Purchase",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Bulk Payment", route: "bulkpayment" },
      ],
    },
  },
  {
    name: "bulkdata",
    path: "/account/bulk-payments/data",
    component: () =>
      import(
        /* webpackChunkName:"user-bulk-data" */ "@/views/user/bulkdata.vue"
      ),
    meta: {
      title: "Bulk Payment | Data Purchase",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Bulk Payment", route: "bulkpayment" },
      ],
    },
  },

  {
    name: "multipaymentDetails",
    path: "/account/bulk-payments/payment-details",
    component: () =>
      import(
        /* webpackChunkName:"user-bulk-payment-details" */ "@/views/user/multipaymentDetails.vue"
      ),
    meta: {
      title: "Bulk Payment | Payment Details",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Bulk Payment", route: "bulkpayment" },
      ],
    },
  },

  {
    name: "fundwallet",
    path: "/account/fund-wallet",
    component: () =>
      import(
        /* webpackChunkName:"user-fund-wallet" */ "@/views/user/fundwallet.vue"
      ),
    meta: {
      title: "Fund Wallet",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
    },
  },

  {
    name: "accountStatement",
    path: "/account/account-statement",
    component: () =>
      import(
        /* webpackChunkName:"account-statement" */ "@/views/user/accountStatement.vue"
      ),
    meta: {
      title: " Account Statement",
      auth: "user",
      roles: ["admin", "operations", "accountant"],
      breadcrumbs: [{ title: "Transactions", route: "transactions" }],
    },
  },
  {
    name: "loan",
    path: "/account/coming-soon",
    component: () =>
      import(/* webpackChunkName:"loans" */ "@/views/user/loans"),
    meta: {
      title: "Coming Soon",
      auth: "user",
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
      // roles:['admin', 'operations','manager']
    },
  },

  //store routes
  {
    name: "ecommerce",
    path: "/account/mall/dashboard",
    component: () =>
      import(
        /* webpackChunkName:"mall-dashboard" */ "@/views/user/store/ecommerce.vue"
      ),
    meta: {
      title: " Store Dashboard",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [{ title: "All Services", route: "allServices" }],
    },
  },
  {
    name: "products",
    path: "/account/mall/products",
    component: () =>
      import(
        /* webpackChunkName:"mall-products" */ "@/views/user/store/products.vue"
      ),
    meta: {
      title: "Store Products",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Mall", route: "ecommerce" },
      ],
    },
  },
  {
    name: "addProduct",
    path: "/account/mall/products/add-product",
    component: () =>
      import(
        /* webpackChunkName:"mall-add-product" */ "@/views/user/store/addProduct.vue"
      ),
    meta: {
      title: "Store Products",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Mall", route: "ecommerce" },
        { title: "Products", route: "products" },
      ],
    },
  },
  {
    name: "orders",
    path: "/account/mall/orders",
    component: () =>
      import(
        /* webpackChunkName:"mall-orders" */ "@/views/user/store/orders.vue"
      ),
    meta: {
      title: "Product Orders",
      auth: "user",
      roles: ["admin", "operations"],
      breadcrumbs: [
        { title: "All Services", route: "allServices" },
        { title: "Mall", route: "ecommerce" },
      ],
    },
  },
];
