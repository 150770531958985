<template>
    <v-dialog v-model="dialog" persistent max-width="420px" transition="dialog-bottom-transition">
        <v-card v-if="data">
            <v-card-text class="d-flex flex-column justify-center align-center pa-6">
                <v-icon size="80" color="error" v-if="data.type == 'error'">mdi-alert-circle</v-icon>
                <h5 class="text-h5 text-center font-weight-bold">{{ data.title }}</h5>
                <p class="text-subtitle-1 px-sm-8 text-center mt-3">{{ data.message }}</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="data.action == 'logout'" @click="logout" color="primary" class="text-capitalize"
                    text>Ok</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { bus } from '@/main';

export default {
    name: "general-dialog",
    data: () => ({
        dialog: false,
        data: null,
    }),
    methods: {
        logout() {
            window.sessionStorage.clear();
            window.localStorage.clear();
            this.dialog = false;
            return this.$router.push({ name: "home" }, () => {
                window.sessionStorage.clear();
                window.location.reload();
            });
        }
    },
    created() {
        bus.$on('showDialog', (data) => {
            this.data = data;
            this.dialog = true
        })
    }
}
</script>


<style scoped></style>
