import Vue from "vue";
import VueRouter from "vue-router";

import { guest } from "@/router/guest";
// import {checkout} from "@/router/checkout";
import { userRoute } from "@/router/userRoute";
import { userRoutes } from "./user";

Vue.use(VueRouter);

const routes = [
  //guest routes
  ...guest,

  //user routes
  ...userRoute,

  {
    name: "layout",
    path: "/account",
    component: () =>
      import(/* webpackChunkName: "layout"*/ "../views/user/index.vue"),
    children: [...userRoutes],
  },

  //403 error
  {
    name: "unauthorized",
    path: "/forbidden",
    component: () =>
      import(/*webpackChunkName: "unauthorized"*/ "@/views/error/unauthorized"),
  },
  {
    name: "notFound",
    path: "*",
    component: () =>
      import(/*webpackChunkName: "error"*/ "@/views/error/notFound"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
