import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import Cookies from "js-cookie";
import { bus } from "./main";

const firebaseConfig = {
  apiKey: "AIzaSyCgaLNCmKtfQV7Iaj_LbLWtmhizSfQag2c",
  authDomain: "app-business-61f8e.firebaseapp.com",
  projectId: "app-business-61f8e",
  storageBucket: "app-business-61f8e.appspot.com",
  messagingSenderId: "714976828571",
  appId: "1:714976828571:web:0b57f252d304956760fa3e",
  measurementId: "G-YKQYRR7HHX",
};

const vapidKey =
  "BG-9HeK7bOiaSfYvX69Qkt35wBORuQiPTOFm7fPdOjiO3TFxNNLkH0gXLyxDcMXdCh7rwL0Bv0Hx7iHYZRdDCQw";

initializeApp(firebaseConfig);

const messaging = getMessaging();

onMessage(messaging, (payload) => {
  // console.log("Message received. ", payload);
  handleNotification(payload);
});

// Listen for messages from the service worker (background notifications sent to active tab)
navigator.serviceWorker.addEventListener("message", function (event) {
  if (event.data && event.data.action === "notification") {
    handleNotification(event.data.payload);
  }
});

function handleNotification(payload) {
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
    icon: "/favicon.png",
    link: "https://dash.useboldd.com",
  };

  // Display custom notification in the main thread
  new Notification(notificationTitle, notificationOptions);

  if (payload.data?.notetype == "sendmoney") {
    // Play sound when receiving a notification
    const audio = new Audio("/notif.mp3");
    audio.play();
    bus.$emit("notify", payload);
  }
}

function getFcmToken() {
  getToken(messaging, { vapidKey })
    .then((currentToken) => {
      if (currentToken) {
        // console.log(currentToken);
        Cookies.set("fcm-token", currentToken, { expires: 1 });
      } else {
        // Show permission request.
        // console.log(
        //   "No registration token available. Request permission to generate one."
        // );
        requestPermission();
      }
    })
    .catch(() => {
      requestPermission();
      // console.log("An error occurred while retrieving token. ", err);
    });
}

function requestPermission() {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      // console.log("Notification permission granted.");
      getFcmToken();
    } else {
      // console.log("Unable to get permission to notify.");
    }
  });
}

getFcmToken();
