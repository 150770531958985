export const guest = [
  {
    name: "index",
    path: "/home",
    component: () => import(/* webpackChunkName:"index" */ "@/views"),
    meta: {
      title: "Boldd Home- Get paid from anywhere, easily",
    },
  },
  {
    name: "home",
    path: "/",
    component: () =>
      import(/* webpackChunkName:"user-login" */ "@/views/auth/login"),
    meta: {
      title: "Boldd Login - Get paid from anywhere, easily",
    },
  },
  {
    name: "authenticate",
    path: "/authenticate",
    component: () =>
      import(/* webpackChunkName:"user-2fa" */ "@/views/authenticate"),
    meta: {
      title: "Two Factor Login - Get paid from anywhere, easily",
      auth: "login",
    },
  },
  {
    name: "setup-2fa",
    path: "/setup-2fa",
    component: () =>
      import(/* webpackChunkName:"setup-2fa" */ "@/views/two-factor"),
    meta: {
      title: "Two Factor Setup - 1app; Get paid from anywhere, easily",
      auth: "login",
    },
  },
  {
    name: "register-user",
    path: "/register/:id?",
    component: () =>
      import(/* webpackChunkName:"user-register" */ "@/views/auth/register"),
    meta: {
      title: "Register- Get paid from anywhere, easily",
    },
    params: true,
  },
  {
    name: "register",
    path: "/register-start/:id?",
    component: () =>
      import(/* webpackChunkName:"user-register" */ "@/views/auth/register"),
    meta: {
      title: "Register- Get paid from anywhere, easily",
    },
    params: true,
  },
  {
    name: "ref-register",
    path: "/r/:refcode",
    beforeEnter: (to, from, next) => {
      next({ name: "register", query: { referby: to.params.refcode } });
    },
  },
  {
    name: "businvite",
    path: "/businvite",
    component: () =>
      import(/* webpackChunkName:"businvite" */ "@/views/new-team"),
    meta: {
      title: "Business Invite - Get paid from anywhere, easily",
    },
  },
  {
    name: "pay-invoice",
    path: "/invoice/:token",
    component: () =>
      import(/* webpackChunkName:"businvite" */ "@/views/settle-invoice"),
    meta: {
      title: "Settle Invoice - Get paid from anywhere, easily",
    },
  },

  {
    name: "businessOption",
    path: "/business-option",
    component: () =>
      import(
        /* webpackChunkName:"user-business-option" */ "@/views/businessOption"
      ),
    meta: {
      title: "Select  Business",
      auth: "user",
    },
  },
  {
    name: "forgotPassword",
    path: "/password-reset",
    component: () =>
      import(
        /* webpackChunkName:"user-forgotPassword" */ "@/views/auth/forgot-password"
      ),
    meta: {
      title: "Boldd Forgot Password- Get paid from anywhere, easily",
    },
  },
  {
    name: "resetPassword",
    path: "/reset-password",
    component: () =>
      import(
        /* webpackChunkName:"user-reset-password" */ "@/views/auth/reset-password"
      ),
    meta: {
      title: "Boldd Reset Password- Get paid from anywhere, easily",
    },
  },
  {
    name: "upgradeAccount",
    path: "/upgrade-account",
    component: () =>
      import(
        /* webpackChunkName:"user-upgrade-account" */ "@/views/user/upgradeAccount"
      ),
    meta: {
      title: "Boldd Upgrade Account- Get paid from anywhere, easily",
    },
  },
];
