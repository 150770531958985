<template>

  <div>
    <v-snackbar vertical multi-line right top v-model="snackbar" :timeout="-1">
      <v-avatar size="30px">
        <v-img src="@/assets/img/logo.png"></v-img>
      </v-avatar>
      Install Boldd to your device. <br>It won't   take up space

      and also works offline!

      <template v-slot:action="{attr}">
        <v-btn text  @click="installPWA">
          <span class="text-capitalize">Install</span>
        </v-btn>
        <v-btn @click="dismissPrompt" text color="error">
          <span class="text-capitalize">Dismiss</span>
        </v-btn>
      </template>

    </v-snackbar>

  </div>


</template>

<script>
import Cookies from "js-cookie";
import {bus} from '@/main'
export default {
  name: "pwaprompt",
  data() {
    return {
      deferredPrompt: null,
      snackbar: false,
      installEvent: null,
      testing: 0
    };
  },
  async beforeCreate() {

    await window.addEventListener("appinstalled", () => {
      this.snackbar = false;
      this.deferredPrompt = null
    });
    window.addEventListener('beforeinstallprompt', async (e) => {
          if (Cookies.get("add-to-home-screen") === undefined) {
            this.installEvent = await e
          }
    })


  },

  methods: {
    dismissPrompt() {
      Cookies.set("add-to-home-screen", null, {expires: 1});
      this.deferredPrompt = null;
      this.snackbar = false

    },
    async installPWA() {

      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the prompt once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
          return this.snackbar = false
        }
        this.snackbar = false
        return console.log('rejected')
      })
    },

  },

  mounted() {

    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      this.snackbar = false;
      this.deferredPrompt = null
    }
     window.addEventListener("appinstalled", () => {
      this.snackbar = false;
      this.deferredPrompt = null
    });

  },
  created() {
    if(Cookies.get("add-to-home-screen") !== undefined) return this.snackbar=false
    bus.$on('workerReady', ()=>{
      this.snackbar=true
    })

  }
}
</script>

<style scoped>

</style>