<template>
  <v-app v-disable-right-click style="background-color: #f8f9fa !important; overflow: hidden">
    <pwaprompt />
    <notification-dialog />
    <general-dialog />
    <router-view :key="$route.fullPath" />
  </v-app>
</template>

<script>
import Pwaprompt from "@/components/user/pwaprompt";
import NotificationDialog from "@/views/user/branch/notification";
import GeneralDialog from "@/components/user/general-dialog";
import("@/assets/css/w3.css");
export default {
  name: "App",
  components: { Pwaprompt, NotificationDialog, GeneralDialog },
  data: () => ({}),
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  color: #444 !important;
  background-color: #f8f9fa !important;
}

*,
body,
html {
  font-family: "Inter", sans-serif !important;
}

.no-transform {
  text-transform: none !important;
}

.history::-webkit-scrollbar {
  width: 1px !important;
  scrollbar-width: thin !important;
}
</style>
