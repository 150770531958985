import Vue from "vue";

Vue.directive("numberFormat", {
  bind(el, binding, vnode) {
    // Target the input element within the v-text-field component
    const input = el.querySelector("input");
    if (input) {
      let isUpdating = false;

      input.addEventListener("input", (event) => {
        if (isUpdating) return;

        let value = event.target.value.replace(/[^0-9\.]/g, ""); // Allow only numbers and decimal point

        // Format the number with commas and limit decimals
        value = formatNumber(value);

        isUpdating = true;
        input.value = value;
        const inputEvent = new Event("input", { bubbles: true });
        input.dispatchEvent(inputEvent);
        isUpdating = false;

        // Update the binding value to ensure `v-model` is in sync
        vnode.context[binding.expression] = value;
      });
    }
  },
  update(el, binding, vnode) {
    // Target the input element within the v-text-field component
    const input = el.querySelector("input");
    if (input && binding.value != null) {
      let value = String(binding.value).replace(/[^0-9\.]/g, ""); // Allow only numbers and decimal point
      value = formatNumber(value);
      input.value = value;
    }
  },
});

function formatNumber(value) {
  if (!value) return "";
  const parts = value.split(".");
  let integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  let decimalPart = parts.length > 1 ? "." + parts[1].slice(0, 2) : ""; // Limit to 2 decimals
  return integerPart + decimalPart;
}
