<template>
  <div>
    <v-snackbar top v-model="snackbar" :timeout="6000" :color="color" class="text-center mt-5">
      <span class="white--text" v-html="message"></span>
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" @click="snackbar = !snackbar" text class="white--text">
          <v-icon class="text-white">mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog" persistent max-width="420px" transition="dialog-bottom-transition">
      <v-card v-if="payload">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="d-flex flex-column justify-center align-center pa-6">
          <img src="@/assets/img/success2.gif" style="max-height: 150px" />
          <h5 class="text-h5 text-center font-weight-bold">{{ payload.notification.title }}</h5>
          <p class="text-subtitle-1 px-sm-8 text-center mt-1">{{ payload.notification.body }}</p>
        </v-card-text>
        <v-card-actions class="px-4 py-6">
          <v-btn
            v-if="transdata.confirm_status != '1'"
            @click="confirmPayment"
            :loading="confirmLoading"
            width="49%"
            elevation="0"
            color="primary"
            class="mr-2"
          >
            <span class="no-transform">Confirm Payment</span>
          </v-btn>
          <v-spacer></v-spacer>
          <receipt width="49%" :details="transdata" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { bus } from "@/main";
import Receipt from "./receipt.vue";
export default {
  name: "notification",
  components: { Receipt },
  data() {
    return {
      color: "",
      snackbar: "",
      message: "",
      confirmLoading: false,
      dialog: false,
      payload: null,
    };
  },
  computed: {
    ...mapGetters(["getUserDetails"]),
    transdata() {
      return JSON.parse(this.payload.data?.transdata ?? "{}");
    },
  },
  methods: {
    async confirmPayment() {
      this.confirmLoading = true;
      await this.$store
        .dispatch("confirmPayment", {
          reference: this.transdata?.txref,
        })
        .then((response) => response.json())
        .then((response) => {
          this.snackbar = true;
          this.message = response.message || response.msg;
          if (response.status) {
            this.color = "success";
            bus.$emit("print-receipt");
            bus.$emit("refresh-trans");
            this.dialog = false;
            return;
          }
          this.color = "error";
        })
        .finally(() => (this.confirmLoading = false));
    },
  },
  created() {
    bus.$on("notify", (data) => {
      this.payload = data;
      this.dialog = true;
    });
  },
};
</script>

<style scoped></style>
