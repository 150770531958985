export default function formatAmount(nStr, n = 2) {
  let a = `${parseFloat(nStr).toFixed(n)}`;
  a += "";
  let x = a.split(".");
  let x1 = x[0];
  let x2 = x.length > 1 ? "." + x[1] : ".00";
  let rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + "," + "$2");
  }
  return x1 + x2;
}

/**
 export  default function formatAmount(nStr)
{
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}
 */
