import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import user from "@/store/user";
import SecureLS from "secure-ls";
let ls = new SecureLS({ isCompression: false });
const vuexLocal = new VuexPersistence({
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
  },
  plugins: [vuexLocal.plugin],
});
